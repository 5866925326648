import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { axiosNewAPI } from "../../api/axios";
import Swal from "sweetalert2";
import { stylesReactSelect } from "../select/styles";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";
import useUser from "../../hooks/useUser";

const ChangeActivity = ({ show, handleClose, data, getData, table = "turns" }) => {
  const dispatch = useDispatch();
  const user = useUser();
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);

  // Fetch available activities on component mount
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axiosNewAPI.get("activities");
        const formattedActivities = response.data.map(activity => ({
          label: activity.activity,
          value: activity.id,
          ...activity
        }));
        setActivities(formattedActivities);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    if (show) {
      fetchActivities();
    }
  }, [show]);

  // Set default selected activity if data is available
  useEffect(() => {
    if (data && data.activity) {
      // Pre-select the current activity if data is available
      setSelectedActivity({
        label: data.activity.activity,
        value: data.activity.id
      });
    }
  }, [data]);

  const handleChange = (selected) => {
    setSelectedActivity(selected);
  };

  // Add formatting functions for date and time
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    
    // Apply timezone adjustment (+3 hours)
    const adjustedDate = new Date(date);
    adjustedDate.setHours(date.getHours() + 3);
    
    return `${adjustedDate.getDate().toString().padStart(2, '0')}/${(adjustedDate.getMonth() + 1).toString().padStart(2, '0')}/${adjustedDate.getFullYear()}`;
  };

  const formatTime = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    
    // Add 3 hours to adjust for timezone difference
    date.setHours(date.getHours() + 3);
    
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };
  
  // Create a combined formatter for date and time
  const formatDateTime = (dateString) => {
    if (!dateString) return "";
    
    const formattedDate = formatDate(dateString);
    const formattedTime = formatTime(dateString);
    
    // Check if the time is "00:00" (all day)
    const isAllDay = formattedTime === "00:00";
    
    // If it's all day, just return the date, otherwise return date and time
    return isAllDay ? formattedDate : `${formattedDate} ${formattedTime}`;
  };

  const submit = async () => {
    if (!selectedActivity) {
      return Swal.fire({
        title: "Error",
        text: "Debe seleccionar una actividad",
        icon: "error"
      });
    }

    // Don't do anything if the selected activity is the same as the current one
    if (data.activity.id === selectedActivity.value) {
      return Swal.fire({
        title: "Información",
        text: "La actividad seleccionada es la misma que la actual",
        icon: "info"
      });
    }

    try {
      dispatch(setLoading(true));

      Swal.fire({
        title: `<h3 style="color:#0d6efd;">Modificar actividad</h3>`,
        html: `<div>
          <p>¿Está seguro de realizar estos cambios?</p>
          <p><span style="font-weight:600;">Turno:</span> ${formatDateTime(data?.date)}</p>
          <p><span style="font-weight:600;">Actividad anterior:</span> ${data.activity.activity}</p>
          <p><span style="font-weight:600;">Nueva actividad:</span> ${selectedActivity.label}</p>
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Sí",
        denyButtonText: "No",
        confirmButtonColor: "green",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Make the API call to update the activity
          const response = await axiosNewAPI.put(`activities/availability/${data.id}/activity`, {
            id_activity: selectedActivity.value,
          });

          if (response.status === 200) {
            Swal.fire({
              title: "¡Éxito!",
              text: "La actividad fue modificada exitosamente",
              icon: "success",
            });
            
            // Refresh the data and close the modal
            getData();
            handleClose();
          } else {
            throw new Error("Error updating activity");
          }
        }
      });
    } catch (error) {
      console.error("Error updating activity:", error);
      Swal.fire({
        title: "Error",
        text: error.response?.data?.message || "Ocurrió un error al cambiar la actividad",
        icon: "error"
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Cambiar actividad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {data && data.date && (
            <Row className="my-4">
              <Col className="fs-6">
                <span>Turno:</span>{" "}
                <span className="fw-bolder">
                  {formatDateTime(data.date)}
                </span>
                <br />
                <span>Actividad Actual:</span>{" "}
                <span className="fw-bolder">
                  {data.activity.activity}
                </span>
              </Col>
            </Row>
          )}
          
          <Row>
            <Col className="mt-3">
              <Form.Label className="label">
                Nueva Actividad <span className="text-danger">*</span>
              </Form.Label>
              <ReactSelect
                options={activities}
                value={selectedActivity}
                onChange={handleChange}
                styles={stylesReactSelect}
                placeholder="Seleccionar actividad"
                isClearable
              />
            </Col>
          </Row>

          <div className="d-flex justify-content-center mt-5">
            <Button
              variant="outline-danger"
              className="me-3"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              variant="outline-primary"
              onClick={submit}
            >
              Cambiar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeActivity;
