import { Table } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, FormControl, FormLabel, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { logout } from "../../services/auth";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";
import { emptyOption } from "../../constants/form";
import { getActivities } from "../../services/activities";
import axios, { axiosNewAPI } from "../../api/axios";
import useUser from "../../hooks/useUser";
import { stylesReactSelect } from "../select/styles";

const ActivitiesTable = () => {
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const user = useUser();
  const [loading, setLoading] = useState(false);

  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState("");
  const [stateFilter, setStateFilter] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosNewAPI.get("activities");
        let result = response.data?.map((e) => {
          return {
            ...e,
            label: e.activity,
            value: e.id,
          };
        });

        // Filter out the "TODAS" activity
        const filteredActivities = result.filter(activity => activity.activity !== "TODAS");

        setActivities(filteredActivities);
        setData(filteredActivities);
        setShowData(filteredActivities);
      } catch (err) {
        console.error("Error fetching activities:", err);
      }
    };
    getData();

    return () => {
      setData([]);
      setShowData([]);
      setActivities([]);
    };
  }, []);

  useEffect(() => {
    let result = data.filter((e) => {
      // Fixed the filter to handle activity as an ID instead of a string
      let activityMatch = activity ? e?.id?.toString() === activity.toString() : true;
      let stateMatch = stateFilter ? e?.id_state?.toString() === stateFilter : true;

      return activityMatch && stateMatch;
    });

    setShowData(result);
  }, [activity, data, stateFilter]);

  const handleRefresh = async (e) => {
    try {
      const response = await axiosNewAPI.get("activities");
      let result = response.data?.map((e) => {
        return {
          ...e,
          label: e.activity,
          value: e.id,
        };
      });

      // Filter out the "TODAS" activity on refresh as well
      const filteredActivities = result.filter(activity => activity.activity !== "TODAS");

      setActivity("");
      setStateFilter("");

      setData(filteredActivities);
      setShowData(filteredActivities);
    } catch (err) {
      console.error("Error refreshing activities:", err);
    }
  };

  const changeState = async (data, state) => {
    if (data) {
      return Swal.fire({
        title: `<h3>${state} actividad</h3>`,
        html: `<div>
          <p>¿Esta seguro de ${state} esta actividad?</p>
          <p><span style="font-weight:600;">Actividad:</span> ${
            data?.activity ? data.activity : ""
          }</p>
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            try {
              setLoading(true);
              let apiResponse;
              
              if (state === "Deshabilitar") {
                // Updated endpoint for disabling activities
                apiResponse = await axiosNewAPI.put(`activities/${data.id}/disableActivity`, {
                  id_user: user?.id,
                });
              } else {
                // Updated endpoint for enabling activities
                apiResponse = await axiosNewAPI.put(`activities/${data.id}/enableActivity`, {
                  id_user: user?.id,
                });
              }

              setLoading(false);

              if (apiResponse.status === 200) {
                await handleRefresh();

                return Swal.fire({
                  title: "¡Éxito!",
                  text: `¡La actividad se ${state === "Deshabilitar" ? "deshabilitó" : "habilitó"} exitosamente!`,
                  icon: "success",
                });
              } else {
                throw new Error(`Error: ${apiResponse.status}`);
              }
            } catch (err) {
              setLoading(false);
              console.error("Error updating activity state:", err);
              
              if (err.response?.status === 403 || err.response?.status === 401) {
                return Swal.fire({
                  title: "Error!",
                  text: "Su sesión expiró, vuelva a iniciar sesión",
                  icon: "warning",
                  willClose: () => {
                    logout();
                    return navigate("/");
                  },
                });
              }
              
              // Show specific error message if available from API
              const errorMessage = err.response?.data?.message || 
                "Ocurrió un error durante el proceso, inténtelo nuevamente";
                
              return Swal.fire({
                title: "¡Error!",
                text: errorMessage,
                icon: "error",
              });
            }
          }
        });
    }
  };

  const formatData = (array) => {
    try {
      if (array?.length) {
        return array.map((e) => {
          // Check if activity is available based on id_state
          const isAvailable = e.id_state === 1;

          return {
            ...e,
            // Map the API data properties to match column names
            actividad: e.activity,
            disponible: isAvailable ? "Si" : "No",
            uuid: uuidv4(),
            action: (function a() {
              return (
                <div className="d-flex justify-content-center">
                  {isAvailable ? (
                    <Button
                      className="me-3"
                      variant="outline-danger"
                      onClick={() => changeState(e, "Deshabilitar")}
                      disabled={loading}
                    >
                      <i className="fa fa-times me-2"></i> Deshabilitar
                    </Button>
                  ) : (
                    <Button
                      className="me-3"
                      variant="outline-success"
                      onClick={() => changeState(e, "Habilitar")}
                      disabled={loading}
                    >
                      <i className="fa fa-check me-2"></i> Habilitar
                    </Button>
                  )}
                </div>
              );
            })(),
          };
        });
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const handleActivities = (e) => {
    if (e) {
      setActivity(e.value);
    } else {
      setActivity("");
    }
  };

  const handleChange = (e) => {
    setStateFilter(e.target.value);
  };

  return (
    <>
      <div>
        <Row className="mt-4 mb-3">
          <Col>
            <FormLabel>Actividad</FormLabel>
            <ReactSelect
              options={
                activities?.length
                  ? [emptyOption, ...activities]
                  : [emptyOption]
              }
              name="actividades"
              onChange={handleActivities}
              // Fixed: Get the corresponding activity object from activities array
              value={
                activity && activities?.length
                  ? activities.find(a => a.value == activity) || ""
                  : ""
              }
              styles={stylesReactSelect}
              isClearable
            />
          </Col>
          <Col>
            <FormLabel>Disponible</FormLabel>
            <FormControl
              as={"select"}
              name="disponible"
              onChange={handleChange}
              value={stateFilter}
            >
              <option value={""}>Seleccionar</option>
              <option value={"1"}>Si</option>
              <option value={"6"}>No</option>
            </FormControl>
          </Col>
        </Row>
        <div className="d-flex justify-content-center mb-5">
          <Button variant="outline-primary" onClick={handleRefresh}>
            Actualizar
          </Button>
        </div>
      </div>
      <Table
        columns={columns.activities}
        dataSource={formatData(showData)}
        rowKey={(row) => row?.uuid}
      />
    </>
  );
};

export default ActivitiesTable;
