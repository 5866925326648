import { useEffect, useState } from "react";
import { Col, Form, FormCheck, Row } from "react-bootstrap";
import axios, { axiosNewAPI } from "../../api/axios";
import Select from "../select/Select";
import { useDispatch } from "react-redux";
import { setLoading, setSchool } from "../../redux/formDataSlice";
import GenericModal from "../modal/generic-modal.jsx";
import SchoolForm from "../school-form/school-form";
import Swal from "sweetalert2";
import { isLocalidadPremium } from "../../constants/premium-localities";

const School = ({ control, errors, setValue, getValues, watch, register }) => {
  const dispatch = useDispatch();
  const isLocalWatch = watch("isLocal");
  const id_provincia = watch("id_provincia");
  const id_localidad = watch("id_localidad");
  const [locations, setLocations] = useState([]);

  const [locals, setLocals] = useState([]);
  const [noLocals, setNoLocals] = useState([]);

  const [exist, setExist] = useState(false);

  const [selectedSchool, setSelectedSchool] = useState("Seleccionar");

  const [showModal, setShowModal] = useState(false);

  // Añadir logs para entender el estado actual
  console.log("isLocalWatch:", isLocalWatch);
  console.log("locals:", locals);
  console.log("noLocals:", noLocals);
  console.log("locations:", locations);
  console.log("selectedSchool:", selectedSchool);
  console.log("id_provincia:", id_provincia);
  console.log("id_localidad:", id_localidad);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setExist((prev) => !prev);
    setShowModal(false);
  };
  
  const getData = async () => {
    try {
      dispatch(setLoading(true));
      
      let [response, locationsResponse] = await Promise.all([
        axiosNewAPI.get("schools"),
        axiosNewAPI.get("localities"),
      ]);
      
      console.log("Escuelas API response:", response.data);
      console.log("Localities API response:", locationsResponse.data);

      const localsArray = [];
      const noLocalsArray = [];

      // Procesar las escuelas con la nueva estructura
      if (Array.isArray(response.data)) {
        response.data.forEach((e) => {
          // Asegurarse de extraer correctamente el id_provincia
          // A veces locality puede ser null o province puede no existir
          const province_id = e.locality?.province?.id || null;
          
          const formattedData = {
            ...e,
            label: e.name, // Ahora es 'name' en lugar de 'nombre'
            value: e.name,
            id_provincia: province_id,
            id_localidad: e.locality?.id || null
          };
          
          // Log para cada escuela procesada
          console.log("Procesando escuela:", e.name, 
            "| id_localidad:", formattedData.id_localidad, 
            "| id_provincia:", formattedData.id_provincia);
          
          // Clasificar como local o no local basado en el campo is_local (1 = local, 0 = no local)
          if (e.is_local === 1) {
            localsArray.push(formattedData);
          } else {
            noLocalsArray.push(formattedData);
          }
        });
      }

      console.log("Escuelas locales procesadas:", localsArray);
      console.log("Escuelas no locales procesadas:", noLocalsArray);

      // Reorganizar las localidades para agruparlas por provincia
      const processedLocations = [];
      const provinces = {};
      
      // Asegurarnos de que estamos usando el formato correcto de datos
      const locationsList = Array.isArray(locationsResponse.data) 
        ? locationsResponse.data 
        : locationsResponse.data?.data || [];

      // Primero agrupar por provincia
      if (Array.isArray(locationsList)) {
        locationsList.forEach(loc => {
          if (!loc.province) return; // Skip if province is missing
          
          const provinceId = loc.province.id;
          
          if (!provinces[provinceId]) {
            provinces[provinceId] = {
              id: provinceId,
              nombre: loc.province.name,
              localidades: []
            };
          }
          
          provinces[provinceId].localidades.push({
            id: loc.id,
            nombre: loc.name
          });
        });
        
        // Convertir el objeto de provincias a un array
        Object.values(provinces).forEach(province => {
          processedLocations.push(province);
        });
      }

      console.log("Locations procesadas:", processedLocations);

      // Establecer los estados con los datos procesados
      setLocals(localsArray);
      setNoLocals(noLocalsArray);
      setLocations(processedLocations);
      
      dispatch(setLoading(false));
    } catch (error) {
      console.error("Error al obtener datos:", error);
      setLocals([]);
      setNoLocals([]);
      setLocations([]);
      dispatch(setLoading(false));
    }
  };

  const handleSubmitSchool = (data) => {
    try {
      dispatch(setLoading(true));
      console.log("Datos de la escuela:", data);
      // Preparar los datos según los requisitos
      const isLocal = data.isLocal === true || data.isLocal === "true";
      const schoolDataToSend = {
        name: data.nombre,
        id_type_school: parseInt(data.id_type_school), // Convertir string a número
        id_localidad: isLocal ? 1 : id_localidad, // Use 1 for local schools
        id_state: 1, // Siempre 1 como requerido
        is_local: isLocal ? 1 : 0 // 1 for local, 0 for non-local
      };
      
      console.log("Enviando datos de nueva escuela:", schoolDataToSend);
      
      axiosNewAPI
        .post("/schools", schoolDataToSend)
        .then(async (response) => {
          await getData();
          dispatch(setLoading(false));
          return Swal.fire({
            title: "¡Éxito!",
            text: `${response.data.message || "Escuela registrada correctamente"}`,
            icon: "success",
          });
        })
        .catch((err) => {
          console.error("axios catch => ", err);
          dispatch(setLoading(false));
          return Swal.fire({
            title: "¡Error!",
            text: `${
              err.response.data.message ||
              "Ocurrió un error, inténtelo nuevamente"
            }`,
            icon: "error",
          });
        });
    } catch (err) {
      console.error("try catch -> ", err);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getData();
    
    // Inicializar el valor de isLocal si no está establecido
    if (isLocalWatch === undefined || isLocalWatch === null) {
      setValue("isLocal", "true");
    }
    
    return () => {};
  }, []);

  useEffect(() => {
    // Solo actualizar el estado de Redux si isLocalWatch tiene un valor
    if (isLocalWatch !== undefined && isLocalWatch !== null) {
      const schoolType = isLocalWatch === "true" || isLocalWatch === true ? "LOCAL" : "NO_LOCAL";
      dispatch(setSchool({
        ...getValues(),
        tipo: schoolType
      }));
    }
    
    // No hacer reset del form aquí para evitar limpiar los datos mientras el usuario interactúa
    // Solo limpiar si realmente cambia isLocalWatch
    if (isLocalWatch) {
      setSelectedSchool("Seleccionar");
      setValue("id_provincia", {
        label: "Seleccionar",
        value: null,
      });
      setValue("id_localidad", {
        label: "Seleccionar",
        value: null,
      });
      setValue("name_school", "Seleccionar");
    }
  }, [dispatch, isLocalWatch, setValue, getValues]);

  const handleChange = (e) => {
    if (e.value === "notfound") {
      setExist(false);
      handleShow(true);
      return;
    } else {
      setExist(true);
      handleClose(false);
    }
    
    // Preparar los datos para Redux con todos los campos necesarios para ambos tipos de escuela
    const schoolType = isLocalWatch === "true" || isLocalWatch === true ? "LOCAL" : "NO_LOCAL";
    
    // Para escuelas no locales, intentar obtener el nombre de la localidad
    let localidadNombre = null;
    if (schoolType === "NO_LOCAL" && id_localidad) {
      const localidadSeleccionada = locations
        ?.find(prov => prov.id === id_provincia?.value)
        ?.localidades?.find(loc => loc.id === id_localidad);
      
      localidadNombre = localidadSeleccionada?.nombre || null;
    }
    
    // Verificar si es una escuela premium
    const isPremium = schoolType === "NO_LOCAL" && localidadNombre && isLocalidadPremium(localidadNombre);
    
    // Datos a guardar en Redux
    const schoolData = {
      ...e,
      tipo: schoolType,
      id_provincia: schoolType === "LOCAL" ? null : id_provincia?.value,
      id_localidad: schoolType === "LOCAL" ? null : id_localidad,
      name_school: e.value,
      // Guardar nombre de localidad e indicador premium
      localidadNombre,
      isPremium,
      // Ajustar propiedades según si es premium o no
      showAllActivities: isPremium, 
      hideActivitySelect: isPremium
    };
    
    console.log("Enviando datos a Redux:", schoolData);
    dispatch(setSchool(schoolData));
    
    // Actualizar el estado local y los valores del formulario
    setSelectedSchool({ ...e, name_school: e.value });
    setValue("name_school", e.value);
    
    // Establecer otro_school según el tipo de escuela
    setValue("otro_school", schoolType === "NO_LOCAL");
  };

  const handleProvinciaChange = (e) => {
    console.log("Provincia seleccionada:", e);
    setValue("id_localidad", {
      label: "Seleccionar",
      value: null,
    });
    setValue("name_school", "Seleccionar");
    setSelectedSchool("Seleccionar");
    setValue("id_provincia", e);
  };

  const handleLocalidadChange = (e) => {
    setValue("name_school", "Seleccionar");
    setSelectedSchool("Seleccionar");
    setValue("id_localidad", e.value);
    // Añadir log para depurar
    console.log("Localidad seleccionada:", e.value);
    
    // Intentar encontrar el nombre de la localidad seleccionada
    let localidadNombre = null;
    if (id_provincia && id_provincia.value) {
      const localidadSeleccionada = locations
        ?.find(prov => prov.id === id_provincia.value)
        ?.localidades?.find(loc => loc.id === e.value);
      
      localidadNombre = localidadSeleccionada?.nombre || null;
    }
    
    // Si la escuela no es local, actualizar el estado en Redux con la nueva localidad
    if (isLocalWatch === "false" || isLocalWatch === false) {
      const isPremium = localidadNombre && isLocalidadPremium(localidadNombre);
      const currentSchoolData = getValues();
      dispatch(setSchool({
        ...currentSchoolData,
        id_localidad: e.value,
        localidadNombre,
        isPremium,
        showAllActivities: isPremium,
        hideActivitySelect: isPremium
      }));
    }
  };

  const schoolData = getValues();

  return (
    <div className="step-form">
      <div className="text-center">
        <h6>Datos de la escuela</h6>
      </div>
      <div className="forms">
        <div className="flex flex-row gap-x-3 mb-4">
          <h3>¿Es una escuela local?</h3>
          <FormCheck
            type="radio"
            {...register("isLocal", {
              onChange: () => {
                // Actualizar Redux inmediatamente cuando el radio button cambia
                dispatch(setSchool({
                  tipo: "LOCAL",
                  showAllActivities: false,
                  hideActivitySelect: false
                }));
              }
            })}
            value={true}
            label="SI"
            checked={isLocalWatch === "true" || isLocalWatch === true}
          />

          <FormCheck
            type="radio"
            {...register("isLocal", {
              onChange: () => {
                // Actualizar Redux inmediatamente cuando el radio button cambia
                dispatch(setSchool({
                  tipo: "NO_LOCAL",
                  showAllActivities: true,
                  hideActivitySelect: true
                }));
              }
            })}
            value={false}
            label="NO"
            checked={isLocalWatch === "false" || isLocalWatch === false}
          />
        </div>
        
        {/* Mostrar los campos de escuela basados en isLocalWatch */}
        {isLocalWatch === "true" || isLocalWatch === true ? (
          <Form.Group>
            <Row className="">
              <Col>
                <Form.Label>Escuela Local</Form.Label>
                <Select
                  name="name_school"
                  control={control}
                  defaultValue={selectedSchool}
                  options={
                    locals?.length > 0
                      ? [
                          ...locals,
                          {
                            label: "- No encuentro mi escuela",
                            value: "notfound",
                          },
                        ]
                      : [
                          {
                            label: "- No se encontraron escuelas -",
                            value: "otro",
                          },
                        ]
                  }
                  value={selectedSchool}
                  className={`${errors.name_school ? "is-invalid" : ""}`}
                  handleChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name_school?.message}
                </Form.Control.Feedback>
                
                {/* Add the modal for local schools here */}
                {!exist && showModal && (
                  <GenericModal
                    title={
                      "Carga los siguientes datos para registrar la escuela"
                    }
                    FormComponent={({ setSubmit }) => (
                      <SchoolForm
                        schoolData={schoolData}
                        handleSubmitSchool={handleSubmitSchool}
                        setSubmit={setSubmit}
                      />
                    )}
                    onHide={handleClose}
                    show={showModal}
                  />
                )}
              </Col>
            </Row>
          </Form.Group>
        ) : isLocalWatch === "false" || isLocalWatch === false ? (
          <Form.Group>
            <Row>
              <Col>
                <div className="mb-4">
                  <Form.Label>Provincia</Form.Label>
                  <Select
                    control={control}
                    name="id_provincia"
                    handleChange={handleProvinciaChange}
                    value={id_provincia}
                    options={locations?.length > 0 ? locations.map((el) => ({
                      label: el.nombre,
                      value: el.id,
                    })) : [{ label: "No hay provincias disponibles", value: "" }]}
                  />
                </div>
                
                {id_provincia && id_provincia.value && (
                  <div className="mb-4">
                    <Form.Label>Localidad</Form.Label>
                    <Select
                      control={control}
                      name="id_localidad"
                      options={locations
                        ?.filter((el) => el.id === id_provincia.value)
                        .flatMap((obj) =>
                          obj.localidades
                            .filter(
                              (localidad) =>
                                localidad.nombre !== "San Nicolas"
                            )
                            .sort((a, b) => a.nombre.localeCompare(b.nombre))
                            .map((localidad) => ({
                              label: localidad.nombre,
                              value: localidad.id,
                            }))
                        )}
                      handleChange={handleLocalidadChange}
                      value={id_localidad?.value}
                    />
                  </div>
                )}
                
                {id_localidad && (
                  <div className="mb-4">
                    <Form.Label>Escuela</Form.Label>
                    <Select
                      name="name_school"
                      control={control}
                      options={(() => {
                        // Mejoramos la lógica de filtrado para manejar id_provincia undefined
                        const filteredSchools = noLocals.filter((escuela) => {
                          const localidadMatch = Number(escuela.id_localidad) === Number(id_localidad);
                          
                          // Si id_provincia de la escuela es undefined o null, solo comparamos localidad
                          // De lo contrario, comparamos ambos
                          const provinciaMatch = escuela.id_provincia === undefined || escuela.id_provincia === null ? 
                            true : // No comparar provincia si es undefined o null
                            Number(escuela.id_provincia) === Number(id_provincia.value);
                          
                          console.log("Escuela:", escuela.label, 
                            "| id_localidad escuela:", escuela.id_localidad, 
                            "| id_localidad seleccionada:", id_localidad,
                            "| Match localidad:", localidadMatch,
                            "| id_provincia escuela:", escuela.id_provincia,
                            "| id_provincia seleccionada:", id_provincia.value,
                            "| Match provincia:", provinciaMatch);
                          
                          return localidadMatch && provinciaMatch;
                        });
                        
                        console.log("Total escuelas filtradas:", filteredSchools.length);
                        
                        if (filteredSchools.length === 0) {
                          // Si no hay escuelas que coincidan, solo mostrar la opción de no encontrar
                          return [
                            {
                              label: "- No hay escuelas para esta ubicación -",
                              value: "nohay",
                              disabled: true
                            },
                            {
                              label: "- No encuentro mi escuela -",
                              value: "notfound",
                            },
                          ];
                        }
                        
                        return [
                          ...filteredSchools,
                          {
                            label: "- No encuentro mi escuela -",
                            value: "notfound",
                          },
                        ];
                      })()}
                      value={selectedSchool}
                      className={`${errors.name_school ? "is-invalid" : ""}`}
                      handleChange={handleChange}
                    />
                    {!exist && showModal && (
                      <GenericModal
                        title={
                          "Carga los siguientes datos para registrar la escuela"
                        }
                        FormComponent={({ setSubmit }) => (
                          <SchoolForm
                            schoolData={schoolData}
                            handleSubmitSchool={handleSubmitSchool}
                            setSubmit={setSubmit}
                          />
                        )}
                        onHide={handleClose}
                        show={showModal}
                      />
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Form.Group>
        ) : null}
      </div>
    </div>
  );
};

export default School;
