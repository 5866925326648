import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { axiosNewAPI } from "../../api/axios"; // Changed from axiosTurnosAPI to axiosNewAPI
import { Upload, Button as AntdButton } from "antd";
import { formatLocalDate } from "../../utils/utils";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";

const Confirmacion = () => {
  const { token } = useParams();
  const { loading } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [fileList, setFileList] = useState([]);
  const [reservationId, setReservationId] = useState(null);

  const getData = async () => {
    try {
      dispatch(setLoading(true));
      // Use the new API to get reservation by token
      let response = await axiosNewAPI.get(`/reservations/token/${token}`);

      setData(response.data);
      // Save the reservation ID for file upload
      if (response.data && response.data.id) {
        setReservationId(response.data.id);
      }
      
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getData();
  }, [token]);

  const handleChange = (e) => {
    if (e?.fileList.length) {
      setFileList([e?.fileList[e?.fileList.length - 1]]);
    } else {
      setFileList([]);
    }
  };

  const handleConfirm = async () => {
    try {
      if (!fileList.length) {
        return Swal.fire({
          html: "<div style='font-size: 22; font-weight: 700;'>Debe cargar el permiso</div>",
          icon: "warning",
        });
      }
      
      if (!reservationId) {
        return Swal.fire({
          title: "Error",
          text: "No se pudo identificar la reserva",
          icon: "error",
        });
      }
      
      dispatch(setLoading(true));
      
      // Create FormData with just the file
      let formData = new FormData();
      formData.append("file", fileList[0].originFileObj);
      
      // Use the new endpoint structure for file upload
      let response = await axiosNewAPI.post(`/reservations/${reservationId}/upload`, formData);

      getData(); // Refresh data after upload

      Swal.fire({
        title: "¡Éxito!",
        text: "El archivo se ha subido correctamente y la reserva ha sido confirmada.",
        icon: "success",
      });
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
      
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al subir el archivo. Por favor, inténtelo de nuevo.",
        icon: "error",
      });
    }
  };

  return (
    <div className="seccion">
      <div className="container">
        <div
          className="text-center f-color fw-700"
          style={{ fontSize: 22, marginTop: 40, marginBottom: 40 }}
        >
          Confirmar reserva
        </div>

        {!loading ? (
          data ? (
            <div>
              <div className="d-flex justify-content-center mb-4">
                <div>
                  <div>
                    <div
                      className="fw-700 pb-2 text-center"
                      style={{ fontSize: 18 }}
                    >
                      <span>Datos de la reserva</span>
                    </div>
                  </div>
                  <div>
                    <span className="fw-700 me-2">Fecha:</span>
                    <span>{data.fecha}</span>
                  </div>
                  <div>
                    <span className="fw-700 me-2">Horario:</span>
                    <span>{data.horario}</span>
                  </div>
                  <div>
                    <span className="fw-700 me-2">Escuela:</span>
                    <span>{data.escuela}</span>
                  </div>
                  <div>
                    <span className="fw-700 me-2">Actividad:</span>
                    <span>{data.actividad}</span>
                  </div>
                  <div>
                    <span className="fw-700 me-2">Cantidad de personas:</span>
                    <span>{data.people}</span>
                  </div>
                  <div>
                    <span className="fw-700 me-2">Estado:</span>
                    <span>{data.estado}</span>
                  </div>
                </div>
              </div>

              {data.estado === "Pendiente" ? (
                <>
                  <div className="d-flex flex-column align-items-center justify-content-center py-5">
                    <div className="mb-3">
                      <span className="fw-700" style={{ fontSize: 16 }}>
                        Adjuntar el permiso de salida educativa
                      </span>
                    </div>
                    <div style={{ width: 400, textAlign: "center" }}>
                      <Upload
                        beforeUpload={() => false}
                        name="files"
                        listType="picture"
                        iconRender={(f) => {
                          if (f.type === "application/pdf") {
                            return (
                              <i
                                className="fa fa-file-pdf-o"
                                style={{ fontSize: 30, color: "#FA0F00" }}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fa fa-file-o"
                                style={{ fontSize: 30 }}
                              ></i>
                            );
                          }
                        }}
                        fileList={fileList}
                        onChange={handleChange}
                      >
                        <AntdButton
                          icon={
                            <i
                              className="fa fa-download"
                              style={{
                                fontSize: 18,
                                marginRight: 10,
                                marginTop: 2,
                              }}
                            ></i>
                          }
                        >
                          {" "}
                          Subir archivo
                        </AntdButton>
                      </Upload>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <Button
                      variant="outline-success"
                      onClick={handleConfirm}
                      className="me-3"
                    >
                      Confirmar
                    </Button>
                    <Button variant="outline-danger">Cancelar</Button>
                  </div>
                </>
              ) : (
                <div className="text-center fw-700" style={{ fontSize: 16 }}>
                  El estado actual de su turno es {data.estado}.
                </div>
              )}
            </div>
          ) : (
            <div className="text-center fw-700" style={{ fontSize: 16 }}>
              Link caducado, el tiempo de confirmación finalizó, su turno fue
              cancelado.
            </div>
          )
        ) : (
          <div className="text-center fw-700" style={{ fontSize: 16 }}>
            Cargando...
          </div>
        )}
      </div>
    </div>
  );
};

export default Confirmacion;
