import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { formatLocalDate } from "../../utils/utils";
import Select from "../select/Select";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { emptyOption } from "../../constants/form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { axiosNewAPI } from "../../api/axios";
import { logout } from "../../services/auth";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";
import { stylesReactSelect } from "../select/styles";
import useUser from "../../hooks/useUser";
import FileModal from "../fileModal/FileModal";
import useAxiosTurnosAPI from "../../hooks/useAxiosTurnosAPI";
import DocumentViewerModal from "../../components/documentViewer/DocumentViewerModal";

import "../../assets/styles/Modals.css";

const DateModal = ({ show, handleClose, data, setEvents, getData }) => {
  const { handleSubmit, control, setValue } = useForm();
  const axiosPrivateTurnosAPI = useAxiosTurnosAPI();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useUser();

  const [dates, setDates] = useState([]);
  const [horaries, setHoraries] = useState([]);
  const [date, setDate] = useState("");
  const [horary, setHorary] = useState("");
  const [turn, setTurn] = useState("");

  const [showFile, setShowFile] = useState(false);
  const [files, setFiles] = useState([]);
  
  // Nuevos estados para manejar la solicitud educativa
  const [loadingFile, setLoadingFile] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  
  // Nuevo estado para el visor de documentos
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);

  // Estado para razones de cancelación
  const [cancelReason, setCancelReason] = useState("");
  // Nuevo estado para almacenar las razones de cancelación del endpoint
  const [cancelReasons, setCancelReasons] = useState([]);

  let reserva = data ? data : "";

  // Cargar el archivo de solicitud educativa cuando se abre el modal
  useEffect(() => {
    if (show && data && data.reservationId) {
      fetchReservationFile(data.reservationId);
    }
  }, [show, data]);

  // Nuevo useEffect para cargar las razones de cancelación
  useEffect(() => {
    if (show) {
      fetchCancelReasons();
    }
  }, [show]);

  // Función para obtener las razones de cancelación del API
  const fetchCancelReasons = async () => {
    try {
      const response = await axiosNewAPI.get("/cancel-reasons");
      if (response.status === 200 && response.data) {
        setCancelReasons(response.data);
      }
    } catch (error) {
      console.error("Error fetching cancel reasons:", error);
    }
  };

  // Función para obtener el archivo de la reserva
  const fetchReservationFile = async (reservationId) => {
    try {
      setLoadingFile(true);
      const response = await axiosNewAPI.get(`/reservations/${reservationId}/file`);
      
      if (response.status === 200 && response.data && response.data.url) {
        setFileUrl(response.data.url);
      } else {
        setFileUrl(null);
      }
    } catch (error) {
      console.error("Error fetching reservation file:", error);
      setFileUrl(null);
    } finally {
      setLoadingFile(false);
    }
  };

  // Función para abrir el archivo en el visor de documentos
  const openReservationFile = () => {
    if (fileUrl) {
      setShowDocumentViewer(true);
    }
  };
  
  // Función para cerrar el visor de documentos
  const handleCloseDocumentViewer = () => {
    setShowDocumentViewer(false);
  };

  const handleChange = (e) => {
    if (e) {
      setDate(e.value);
    } else {
      setDate("");
    }
  };

  const handleTurn = (e) => {
    setTurn(e);
    setHorary(e);
  };

  const handleCancelReasonChange = (e) => {
    setCancelReason(e.target.value);
  };

  const deleteReserves = async () => {
    if (data) {
      // Verificar si tenemos razones de cancelación cargadas
      if (cancelReasons.length === 0) {
        return Swal.fire({
          title: "Error",
          text: "No se pudieron cargar las razones de cancelación. Intente nuevamente.",
          icon: "error",
        });
      }

      // Filtrar las razones para excluir la opción AUTO_CANCEL
      const filteredReasons = cancelReasons.filter(reason => 
        reason.name !== "AUTO_CANCEL" && reason.description !== "AUTO_CANCEL"
      );

      // Crear las opciones del select como elementos de radio
      const radioOptions = filteredReasons.map(reason => 
        `<div class="form-check mb-2">
          <input class="form-check-input" type="radio" name="cancelReason" id="reason-${reason.id}" value="${reason.id}">
          <label class="form-check-label text-left" for="reason-${reason.id}">
            ${reason.description}
          </label>
        </div>`
      ).join('');

      // Mostrar el modal con opciones de radio en lugar de un select
      return Swal.fire({
        title: `<h3 style="color:#dc3741;">Cancelar turno</h3>`,
        html: `<div>
          <p>¿Está seguro de cancelar este turno?</p>
          <p class="text-center mt-2"><strong>Por favor, seleccione el motivo de la cancelación:</strong></p>
          <div style="text-align: left; margin-top: 15px; margin-bottom: 15px;">
            ${radioOptions}
          </div>
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
        width: '600px', // Aumentar el ancho del modal
        preConfirm: () => {
          // Validar que se haya seleccionado una razón antes de permitir la confirmación
          const selectedReason = document.querySelector('input[name="cancelReason"]:checked');
          if (!selectedReason) {
            Swal.showValidationMessage('Debe seleccionar una razón para la cancelación');
            return false;
          }
          return selectedReason.value;
        }
      })
        .then(async (result) => {
          if (result.isConfirmed && result.value) {
            dispatch(setLoading(true));
            
            // Guardar la razón seleccionada
            const selectedReason = result.value;
            
            // Usar el endpoint API con el reservationId y la razón de cancelación
            let response = await axiosNewAPI.put(
              `/reservations/${data.reservationId}/cancel`,
              { id_cancel_reason: selectedReason }
            );

            if (response.status === 200) {
              getData(); // Actualizar el calendario
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Éxito!",
                text: "Se canceló exitosamente.",
                icon: "success",
              });
            } else {
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Error!",
                text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
                icon: "error",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoading(false));
          if (err.response?.status === 403 || err.response?.status === 401) {
            return Swal.fire({
              title: "¡Error!",
              text: "Su sesión expiro, vuelva a iniciar sesión.",
              icon: "warning",
              willClose: () => {
                logout();
                return navigate("/");
              },
            });
          }
          return Swal.fire({
            title: "¡Error!",
            text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
            icon: "error",
          });
        });
    } else {
      return;
    }
  };

  const confirmReserve = async () => {
    if (data) {
      return Swal.fire({
        title: `<h3 style="color:#198754;">Confirmar turno</h3>`,
        html: `<div>
          <p>¿Esta seguro de confirmar este turno?</p>
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            dispatch(setLoading(true));
            
            // Usar el nuevo endpoint de API con el reservationId
            let response = await axiosNewAPI.put(
              `/reservations/${data.reservationId}/confirm`
            );

            if (response.status === 200) {
              getData(); // Actualizar el calendario
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Éxito!",
                text: "El turno se confirmó exitosamente.",
                icon: "success",
              });
            } else {
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Error!",
                text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
                icon: "error",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoading(false));
          if (err.response?.status === 403 || err.response?.status === 401) {
            return Swal.fire({
              title: "¡Error!",
              text: "Su sesión expiro, vuelva a iniciar sesión.",
              icon: "warning",
              willClose: () => {
                logout();
                return navigate("/");
              },
            });
          }
          return Swal.fire({
            title: "¡Error!",
            text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
            icon: "error",
          });
        });
    } else {
      return;
    }
  };

  const submit = (values) => {
    if (!values.fecha || !values.horario || !turn)
      return Swal.fire(
        "Incompleto",
        "Debe elegir una fecha y horario",
        "warning"
      );
    if (data?.reserves?.length > turn.disponibles)
      return Swal.fire(
        "Turnos insuficientes",
        "No hay suficientes turnos en esta fecha",
        "warning"
      );

    return Swal.fire({
      title: `<h3 style="color:#0d6efd;">Modificar turnos</h3>`,
      html: `<div>
        <p>¿Esta seguro de estos cambios?</p>
        <p><span style="font-weight:600;">Anterior fecha:</span> ${
          data.fecha ? formatLocalDate(data.fecha) : ""
        } a las ${data.horario ? data.horario : ""}</p>
        <p><span style="font-weight:600;">Nueva fecha:</span> ${formatLocalDate(
          values.fecha
        )} a las ${values.horario}</p>
      </div>`,
      icon: `warning`,
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: "No",
      confirmButtonColor: "green",
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          dispatch(setLoading(true));

          // let response = await axiosTurnosAPI.patch("/reserves/changeTurn", {
          //   id: data.Reservas[0].id,
          //   idTurn: data.turnId,
          //   newTurn: turn.id,
          //   id_usuario: user?.id,
          //   prevTurn: `${data.fecha ? formatLocalDate(data.fecha) : ""} a las ${
          //     data.horario ? data.horario : ""
          //   }`,
          //   nextTurn: `${formatLocalDate(values.fecha)} a las ${
          //     values.horario
          //   }`,
          //   reserva: {
          //     actividad: data?.restriccion?.unidad?.name,
          //     escuela:
          //       data?.Reservas[0].Agentes_Subagente.Agente?.agente_entidad
          //         ?.entidad?.nombre,
          //     grado: `${data?.Reservas[0].Agentes_Subagente.Subagente?.nombre}`,
          //     division: `${data?.Reservas[0].Agentes_Subagente.Subagente?.division}`,
          //     representante: `${data?.Reservas[0].Agentes_Subagente.Agente?.nombre} ${data?.Reservas[0].Agentes_Subagente.Agente?.apellido}`,
          //     email: data?.Reservas[0].Agentes_Subagente.Agente?.email,
          //   },
          // });

          if (response.status === 200) {
            getData();

            dispatch(setLoading(false));

            return Swal.fire({
              title: "¡Éxito!",
              text: "El turno fue modificado exitosamente",
              icon: "success",
              willClose: () => handleClose(),
            });
          } else {
            dispatch(setLoading(false));
            return Swal.fire({
              title: "Error!",
              text: "Ocurrio un error durante el proceso, intentelo nuevamente",
              icon: "error",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 403 || err.response?.status === 401) {
          dispatch(setLoading(false));
          return Swal.fire({
            title: "Error!",
            text: "Su sesión expiro, vuelva a iniciar sesión",
            icon: "warning",
            willClose: () => {
              logout();
              return navigate("/");
            },
          });
        }
        dispatch(setLoading(false));

        return Swal.fire({
          title: "Error!",
          text: "Ocurrio un error durante el proceso, intentelo nuevamente",
          icon: "error",
        });
      });
  };

  const handleShowFile = (e) => {
    setShowFile(true);
    setFiles(e);
  };

  const handleCloseFile = (e) => {
    setShowFile(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" className="date-modal">
      <Modal.Header>
        <div className="d-flex flex-column justify-content-center w-100">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div className="text-center">
            <h5>TURNO</h5>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column turn-info">
          <div className="mt-3">
            <span className="info-name">Actividad:</span>
            <span>{data?.activity?.activity}</span>
          </div>
          <div>
            <span className="info-name">Fecha:</span>
            <span>
              {data?.activitiesavailability?.date 
                ? (() => {
                    const date = new Date(data.activitiesavailability.date);
                    // Ajustar por desfase horario (añadir 3 horas como hicimos en Calendar)
                    const adjustedDate = new Date(date.getTime() + (3 * 60 * 60 * 1000));
                    return formatLocalDate(adjustedDate); // Solo la fecha sin la hora
                  })()
                : ""}
            </span>
          </div>
          
          {/* Verificar si es una actividad "TODAS" para escuelas no locales */}
          {data?.activity?.activity !== "TODAS LAS ACTIVIDADES" && 
           data?.activity?.activity !== "TODAS" ? (
            <div>
              <span className="info-name">Horario:</span>
              <span>{data?.horario}</span>
            </div>
          ) : (
            <div>
              <span className="info-name">Horario:</span>
              <span>Todo el día</span>
            </div>
          )}
          
          <div>
            <span className="info-name">Estado:</span>
            <span>{data?.state?.name}</span>
          </div>
          
          {/* Mostrar la razón de cancelación si el turno está cancelado */}
          {data?.state?.name === "Cancelado" && data?.cancelReason && (
            <div>
              <span className="info-name">Razón de cancelación:</span>
              <span>{data?.cancelReason?.description}</span>
            </div>
          )}
          
          <div>
            <span className="info-name">Personas:</span>
            <span>{data?.people}</span>
          </div>
          {/* Agregar información de la escuela */}
          <div>
            <span className="info-name">Escuela:</span>
            <span>{data?.school?.name || "No especificada"}</span>
          </div>
          <div>
            <span className="info-name">Escuela local:</span>
            <span>{data?.school?.is_local === 1 ? "Sí" : "No"}</span>
          </div>
          <div>
            <span className="info-name">Nivel:</span>
            <span>{data?.school?.id_type_School === 1 ? "Primaria" : "Secundaria"}</span>
          </div>
          {/* Agregar email del solicitante */}
          <div>
            <span className="info-name">Email del solicitante:</span>
            <span>{data?.email || "No especificado"}</span>
          </div>
          
          {/* Agregar número de teléfono móvil del solicitante */}
          <div>
            <span className="info-name">Teléfono del solicitante:</span>
            <span>{data?.mobilephone || "No especificado"}</span>
          </div>
          
          {/* Información del archivo de solicitud educativa */}
          {loadingFile ? (
            <div className="my-2">
              <span className="info-name">Solicitud educativa:</span>
              <span>Cargando...</span>
            </div>
          ) : fileUrl ? (
            <div className="my-2">
              <span className="info-name">Solicitud educativa:</span>
              <Button 
                variant="outline-primary" 
                size="sm" 
                className="ms-2"
                onClick={openReservationFile}
              >
                <i className="fa fa-file-pdf-o me-1"></i> Ver documento
              </Button>
            </div>
          ) : null}
        </div>

        <div className="d-flex justify-content-center mt-5">
          {data?.state?.name !== "Cancelado" && (
            <Button
              variant="outline-danger"
              className="me-3"
              onClick={() => deleteReserves()}
            >
              <i className="fa fa-remove me-2"></i> Cancelar
            </Button>
          )}
          {data?.state?.name !== "Confirmado" && (
            <Button
              className="me-3"
              variant="outline-success"
              onClick={() => confirmReserve()}
            >
              <i className="fa fa-check me-2"></i> Confirmar
            </Button>
          )}
          <Button variant="outline-dark" onClick={handleClose}>
            Cerrar
          </Button>
        </div>
        {showFile ? (
          <FileModal
            show={showFile}
            handleClose={handleCloseFile}
            files={files}
          />
        ) : (
          ""
        )}
      </Modal.Body>
      
      {/* Modal para visualizar documentos */}
      <DocumentViewerModal
        show={showDocumentViewer}
        handleClose={handleCloseDocumentViewer}
        fileUrl={fileUrl}
      />
    </Modal>
  );
};

export default DateModal;