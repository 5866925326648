import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DocumentViewerModal = ({ show, handleClose, fileUrl }) => {
  // Función para determinar el tipo de archivo basado en la URL
  const getFileType = (url) => {
    if (!url) return null;
    
    const lowerUrl = url.toLowerCase();
    if (lowerUrl.endsWith('.pdf')) return 'pdf';
    if (lowerUrl.endsWith('.doc') || lowerUrl.endsWith('.docx')) return 'doc';
    return 'unknown';
  };

  const fileType = getFileType(fileUrl);

  // Renderizar diferentes visores según el tipo de archivo
  const renderDocumentViewer = () => {
    if (!fileUrl) return <p>No se pudo cargar el documento</p>;

    switch (fileType) {
      case 'pdf':
        // Visor nativo para PDFs
        return (
          <iframe
            src={fileUrl}
            width="100%"
            height="500px"
            title="PDF Viewer"
            style={{ border: 'none' }}
          ></iframe>
        );
      
      case 'doc':
        // Usar Google Docs Viewer para archivos DOC/DOCX
        const googleDocsUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`;
        return (
          <iframe
            src={googleDocsUrl}
            width="100%"
            height="500px"
            title="Document Viewer"
            style={{ border: 'none' }}
          ></iframe>
        );
      
      default:
        return (
          <div className="text-center p-5">
            <p>Este tipo de archivo no se puede previsualizar.</p>
            <Button 
              variant="primary" 
              href={fileUrl} 
              target="_blank"
              rel="noopener noreferrer"
            >
              Descargar archivo
            </Button>
          </div>
        );
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Vista previa del documento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="document-viewer-container">
          {renderDocumentViewer()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button 
          variant="primary" 
          href={fileUrl} 
          target="_blank"
          rel="noopener noreferrer"
        >
          Abrir en nueva pestaña
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentViewerModal;
