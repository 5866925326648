import React, { useEffect } from "react";
import { Col, Form, FormCheck, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

const SchoolForm = ({ schoolData, handleSubmitSchool, setSubmit }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (setSubmit) {
      setSubmit(() =>
        handleSubmit((formData) => {
          const hasErrors = Object.keys(errors).length > 0;
          if (hasErrors) {
            return false; // Retorna false si hay errores, para evitar cerrar el modal
          }
          
          // Determine if it's a local school based on schoolData
          const isLocal = schoolData.isLocal === true || schoolData.isLocal === "true";
          
          // Prepare the data with the correct values based on school type
          const preparedData = {
            ...formData,
            ...schoolData,
            // Override specific fields regardless of what's in schoolData
            is_local: isLocal ? 1 : 0, // 1 for local schools, 0 for non-local
            id_localidad: isLocal ? 1 : schoolData.id_localidad, // 1 for local schools
            id_state: 1 // Always 1 as required
          };
          
          return handleSubmitSchool(preparedData);
        })
      );
    }
  }, [handleSubmit, handleSubmitSchool, setSubmit, schoolData, errors]);

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        // Determine if it's a local school based on schoolData
        const isLocal = schoolData.isLocal === true || schoolData.isLocal === "true";
        
        // Prepare the data with the correct values based on school type
        const preparedData = {
          ...formData,
          ...schoolData,
          // Override specific fields regardless of what's in schoolData
          is_local: isLocal ? 1 : 0, // 1 for local schools, 0 for non-local
          id_localidad: isLocal ? 1 : schoolData.id_localidad, // 1 for local schools
          id_state: 1 // Always 1 as required
        };
        
        return handleSubmitSchool(preparedData);
      })}
      className="p-8"
    >
      <div className="forms">
        <Row className="mb-3">
          <Col sm={12}>
            <Form.Group>
              <Form.Label>Nombre de escuela</Form.Label>
              <Form.Control
                name="nombre"
                {...register("nombre", {
                  required: "El nombre es requerido",
                })}
                isInvalid={!!errors.nombre} // Clase de Bootstrap para error
              />
              <Form.Control.Feedback type="invalid">
                {errors.nombre && errors.nombre?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12}>
            <Form.Group>
              <Form.Label>Tipo de Escuela</Form.Label>
              <FormCheck
                type="radio"
                label="Primaria"
                value="1"
                {...register("id_type_school", {
                  required: "Debe seleccionar si es primaria o secundaria",
                })}
                isInvalid={!!errors.id_type_school}
              />
              <FormCheck
                type="radio"
                label="Secundaria"
                value="2"
                {...register("id_type_school", {
                  required: "Debe seleccionar si es primaria o secundaria",
                })}
                isInvalid={!!errors.id_type_school}
              />
              <Form.Control.Feedback type="invalid">
                {errors.id_type_school?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default SchoolForm;
