export const columns = {
  turns: [
    {
      title: 'Fecha del turno',
      dataIndex: 'fecha',
      key: 'fecha',
    },
    {
      title: 'Horario',
      dataIndex: 'horario',
      key: 'horario',
    },
    {
      title: 'Actividad',
      dataIndex: 'actividad',
      key: 'actividad',
    },
    {
      title: "Estado de la actividad",
      dataIndex: "estado",
      key: "estado",
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      align: "center"
    },
  ]
}