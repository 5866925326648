export const columns = {
  activities: [
    {
      title: 'Actividad',
      dataIndex: 'actividad',
      key: 'actividad',
    },
    {
      title: 'Disponible',
      dataIndex: 'disponible',
      key: 'disponible',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      align: "center"
    },
  ]
}