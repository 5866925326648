import React, { useEffect, useState } from 'react'
import { Card, Tab, Tabs } from 'react-bootstrap'
import { useDispatch } from "react-redux";

import { getGrades } from '../../redux/actionsAsync';

import Calendar from '../../components/calendar';
import ActivitiesTable from '../../components/activitiesTable';
import WeekTable from '../../components/weekTable';
import TurnsTable from '../../components/turnsTable/TurnsTable';
import TurnsAdmin from '../../components/turnsAdmin/TurnsAdmin';

import "../../assets/styles/Admin.css";


const Admin = () => {
  const dispatch = useDispatch();
  const [key, setKey] = useState('calendar');

  useEffect(() => {
    dispatch(getGrades());
  }, [dispatch]);


  return (
    <div>
      <div className='container-fluid admin-container'>
        <Card>
          <Card.Body>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3 fs-5"
            >
              <Tab eventKey="calendar" title="Calendario">
                {key === "calendar" ? (
                  <Calendar />
                ) : ""}
              </Tab>
              <Tab eventKey="dates" title="Administrar Fechas y Horarios">
                {key === "dates" ? (
                  <TurnsTable />
                ) : ""}
              </Tab>
              <Tab eventKey="activities" title="Administrar actividades">
                {key === "activities" ?
                  (<ActivitiesTable />
                  ) : ""}
              </Tab>
              {/* <Tab eventKey="week" title="Administrar días de las actividades">
                {key === "week" ? (
                  <WeekTable />
                ) : ""}
              </Tab> */}
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default Admin