// import { Form, Button, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/auth";
import { useEffect } from "react";

import "../../assets/styles/LoginForm.css";

const LoginModal = ({ show, handleClose }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setShowPassword(false);
      setUsername("");
      setPassword("");
    };
  }, [show]);

  const handleChange = (e) => {
    if (e.target.name === "username") {
      setUsername(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = async () => {
    if (!username || !password) {
      if (!username && !password) {
        return Swal.fire(
          "Incompleto",
          "Debe completar el nombre de usuario y contraseña",
          "warning"
        );
      } else if (!username) {
        return Swal.fire(
          "Incompleto",
          "Debe completar el nombre de usuario",
          "warning"
        );
      } else {
        return Swal.fire(
          "Incompleto",
          "Debe completar la contraseña",
          "warning"
        );
      }
    }

    login({ username, password })
      .then((response) => {
        if (response?.status === 200 && response?.data?.id) {
          handleClose();
          return navigate("/admin");
        } else {
          return Swal.fire("Error!", `Error: ${response.data}`, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        return Swal.fire("Error!", `${err.response.data.error}`, "error");
      });
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose} className="login-modal">
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title className="modalTitle">Iniciar sesión</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
        <div className="login-seccion">
          <Form.Label className="fw-700" style={{ fontSize: 16 }}>
            Usuario <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control name="username" onChange={handleChange} />
        </div>
        <div className="login-seccion">
          <Form.Label className="fw-700" style={{ fontSize: 16 }}>
            Contraseña <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <div>
            <Form.Control
              name="password"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              autoComplete="off"
            />
            {showPassword ? (
              <i
                className="fa fa-eye login-icons"
                onClick={() => setShowPassword(false)}
              ></i>
            ) : (
              <i
                className="fa fa-eye-slash login-icons"
                onClick={() => setShowPassword(true)}
              ></i>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center mt-3">
        <Button
          variant="primary"
          onClick={handleSubmit}
          className="mt-3 mb-3 bg-[#0d6efd]"
        >
          Iniciar sesión
        </Button>
        <Button
          variant="danger"
          onClick={handleClose}
          className="mt-3 mb-3 bg-[#dc3545]"
        >
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModal;
