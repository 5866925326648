/**
 * Lista de nombres de localidades premium que pueden reservar los miércoles
 */
export const PREMIUM_LOCALITIES_NAMES = [
  'Ramallo',
  'San Pedro',
  'Baradero',
  'Pergamino',
  'Zarate',
  'Junin'
];

/**
 * Verifica si una localidad es premium basada en su nombre
 * @param {string} localidadNombre - Nombre de la localidad a verificar
 * @returns {boolean} - True si es una localidad premium
 */
export const isLocalidadPremium = (localidadNombre) => {
  if (!localidadNombre) return false;
  return PREMIUM_LOCALITIES_NAMES.includes(localidadNombre);
};
