import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Col, Form, Row, Button, Alert } from "react-bootstrap";
import { sortArray } from "../../utils/utils";
import axios, { axiosNewAPI, axiosTurnosAPI } from "../../api/axios";
import Calendar from "react-calendar";
import Select from "../select/Select";
import { Controller } from "react-hook-form";
import { emptyOption } from "../../constants/form";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";
import "../../assets/styles/Forms.css";
import { isLocalidadPremium } from "../../constants/premium-localities";
import Swal from "sweetalert2";

const TurnForm = ({
  register,
  control,
  errors,
  setValue,
  formState,
  setActivities: setParentActivities,
  setActivity,
  activity,
  setAvailabilityDates
}) => {
  const { escuela } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const [activities, setActivities] = useState([]);
  const [dates, setDates] = useState("");
  const [horaries, setHoraries] = useState("");

  // const [activity, setActivity] = useState("");
  const [date, setDate] = useState("");
  const [teatroMessage, setTeatroMessage] = useState(null);
  const [turn, setTurn] = useState("");

  // Get the school type from Redux state, defaulting to non-local if undefined
  const isLocalSchool = escuela?.tipo?.toUpperCase() === "LOCAL";
  // Determinar si es una escuela no local premium
  const isPremiumNonLocalSchool = !isLocalSchool && 
    escuela?.localidadNombre && isLocalidadPremium(escuela.localidadNombre);
  
  // Nueva variable: controla si se debe ocultar la selección de actividad y usar "TODAS"
  const shouldUseAllActivities = isPremiumNonLocalSchool;
  
  // Nueva variable: controla si se debe deshabilitar la selección de horario
  const shouldDisableTimeSelection = isPremiumNonLocalSchool;
  
  console.log("School type from Redux:", escuela?.tipo);
  console.log("Is local school calculated:", isLocalSchool);
  console.log("Is premium non-local school:", isPremiumNonLocalSchool);
  console.log("Should use all activities:", shouldUseAllActivities);
  console.log("Should disable time selection:", shouldDisableTimeSelection);

  // If escuela is undefined or type is not set, we'll assume it's not set yet
  const isSchoolSelected = !!escuela && !!escuela.tipo;
  
  console.log("School localidad:", escuela?.localidadNombre);

  // Only try to fetch activities if school is selected
  useEffect(() => {
    if (!isSchoolSelected) {
      return; // Don't do anything if school is not selected yet
    }
    
    dispatch(setLoading(true));
    const getActivities = async () => {
      try {
        // Usar endpoint diferente para escuelas premium no locales
        const endpoint = isPremiumNonLocalSchool ? "activities/schools" : "activities/available";
        
        console.log(`Llamando endpoint ${endpoint} para escuela ${isPremiumNonLocalSchool ? "premium no local" : "normal"}`);
        let response = await axiosNewAPI.get(endpoint);
        console.log("Activities API response:", response.data);
        
        if (response.data && Array.isArray(response.data)) {
          // Si es escuela NO LOCAL PREMIUM, procesamos las actividades y fechas de manera especial
          if (shouldUseAllActivities) {
            // Mapear actividades para el selector (aunque no lo mostremos)
            let result = response.data.map((e) => {
              return {
                ...e,
                label: e.activity || e.name, // Adaptamos para manejar ambos formatos de API
                value: e.activity || e.name,
              };
            });
            setActivities(result);
            
            // Para escuelas premium, buscamos específicamente la actividad "TODAS"
            const todasActivity = response.data.find(act => act.activity === "TODAS");
            console.log("Actividad TODAS encontrada:", todasActivity);
            
            if (todasActivity) {
              // Crear actividad combinada usando datos reales de la actividad TODAS
              const combinedActivity = {
                id: todasActivity.id || 4, // Usar ID real o fallback a 4
                activity: "TODAS LAS ACTIVIDADES",
                label: "TODAS LAS ACTIVIDADES",
                value: "TODAS LAS ACTIVIDADES"
              };
              
              // Obtener solo las disponibilidades de la actividad TODAS
              let allAvailabilities = [];
              
              if (todasActivity.availabilities && Array.isArray(todasActivity.availabilities)) {
                // Añadir el nombre de la actividad a cada disponibilidad
                const availabilitiesWithActivityName = todasActivity.availabilities.map(avail => ({
                  ...avail,
                  activityName: "TODAS LAS ACTIVIDADES"
                }));
                
                allAvailabilities = [...availabilitiesWithActivityName];
              }
              
              console.log("Fechas disponibles para TODAS:", allAvailabilities);
              
              // Formatear todas las fechas
              const formattedDates = formatAvailabilityDates(allAvailabilities);
              console.log("Fechas formateadas para TODAS:", formattedDates);
              
              // Para escuelas premium, filtrar solo fechas que caen en miércoles
              const wednesdayDates = formattedDates.filter(dateObj => {
                const date = new Date(dateObj.formattedDate);
                // El día 3 es miércoles (0 = domingo, 1 = lunes, ..., 6 = sábado)
                return date.getDay() === 2;
              });
              console.log("Fechas filtradas (solo miércoles):", wednesdayDates);
              setDates(wednesdayDates);
              
              // También actualizar availabilityDates en el componente padre
              if (setAvailabilityDates) {
                // Filtrar solo las que caen en miércoles
                const wednesdayAvailabilities = allAvailabilities.filter(avail => {
                  const date = new Date(avail.date);
                  return date.getDay() === 3;
                });
                setAvailabilityDates(wednesdayAvailabilities);
              }
              
              // Seleccionar la actividad combinada
              setActivity(combinedActivity);
              setValue("actividades", combinedActivity.value);
              // Guardar el ID de activity explícitamente
              setValue("id_activity", todasActivity.id || 4);
              
              if (setParentActivities) {
                setParentActivities(result);
              }
            } else {
              // Si no se encuentra TODAS, mostrar mensaje de error
              console.error("Error: No se encontró la actividad TODAS");
              Swal.fire({
                title: 'Error',
                text: 'No se pudo encontrar la actividad reservada para escuelas premium',
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              setActivities([]);
              setDates([]);
            }
          } else {
            // Procesar normalmente para el resto de escuelas
            let result = response.data.map((e) => {
              return {
                ...e,
                label: e.activity,
                value: e.activity,
              };
            });
            setActivities(result);
            setActivity("");
            setValue("actividades", "");
            
            if (setParentActivities) {
              setParentActivities(result);
            }
          }
        } else {
          console.error("Invalid activities data format");
          setActivities([]);
          if (setParentActivities) {
            setParentActivities([]);
          }
        }
      } catch (error) {
        console.error("Error fetching activities:", error);
        setActivities([]);
        if (setParentActivities) {
          setParentActivities([]);
        }
      } finally {
        dispatch(setLoading(false));
      }
    };

    getActivities();
    
    // Reset valores solo si no es una escuela premium no local
    if (!shouldUseAllActivities) {
      setTurn("");
      setValue("horary", "");
      setDate("");
      setValue("date", "");
    }
  }, [dispatch, escuela, setValue, isSchoolSelected, setParentActivities, shouldUseAllActivities, isPremiumNonLocalSchool]);

  // Función auxiliar para formatear las fechas de disponibilidad
  const formatAvailabilityDates = (availabilityData) => {
    return availabilityData.map(item => {
      // Parse the date from the API
      const apiDate = new Date(item.date);
      
      // Subtract 3 hours to adjust for the time difference
      const adjustedDate = new Date(apiDate);
      adjustedDate.setHours(apiDate.getHours() + 3);
      
      // Format time consistently without leading zeros
      const timeWithoutLeadingZero = format(adjustedDate, "H:mm");
      
      return {
        ...item,
        // Format the date to yyyy-MM-dd for easy comparison
        formattedDate: format(adjustedDate, "yyyy-MM-dd"),
        // Extract time without leading zeros for consistent comparison
        time: timeWithoutLeadingZero,
        // Add another field with leading zeros if needed elsewhere
        formattedTime: format(adjustedDate, "HH:mm"),
        // Keep the original date for reference
        originalDate: apiDate
      };
    });
  };

  // Modificar el efecto para obtener fechas para escuelas LOCALES y NO LOCALES NO PREMIUM
  // Para escuelas NO LOCALES PREMIUM ya se obtuvieron las fechas en getActivities
  useEffect(() => {
    if (activity?.activity && (isLocalSchool || (!isPremiumNonLocalSchool && !isLocalSchool))) {
      const getDates = async () => {
        dispatch(setLoading(true));
        try {
          let response = await axiosNewAPI.get(
            `/activities/${activity?.id}/availability`
          );
          setAvailabilityDates(response.data);
          console.log("Dates API response:", response.data);
          
          // Formatear fechas usando la función auxiliar
          const formattedDates = formatAvailabilityDates(response.data);
          
          console.log("Formatted dates with time adjustment:", formattedDates);
          setDates(formattedDates);
          setTurn("");
          setDate("");
          setValue("date", "");
          setValue("horary", "");
        } catch (error) {
          console.error("Error fetching dates:", error);
          setDates([]);
        } finally {
          dispatch(setLoading(false));
        }
      };

      getDates();
    }
  }, [activity, dispatch, setValue, isLocalSchool, isPremiumNonLocalSchool]);

  useEffect(() => {
    if (activity?.activity) {
      if (activity && activity.activity === "OBRA DE TEATRO") {
        setTeatroMessage(
          `Por favor comuniquese con el teatro para solicitar y coordinar esta visita.
          Correo de contacto: info@teatrosannicolas.com.ar`
        );
      } else {
        setTeatroMessage(null);
        // Removing the getHorary function and API call
        // since we already have the date and time from the availability endpoint
      }
    }
    return () => {
      setHoraries([]);
    };
  }, [activity]);

  const handleSelect = async (event) => {
    setActivity(event);
    setValue("actividades", event?.value);
    
    if (event.value === "OBRA DE TEATRO") {
      setTeatroMessage(`Por favor comuniquese con el teatro para solicitar y coordinar esta visita.
      Correo de contacto: info@teatrosannicolas.com.ar`);
    } else {
      setTeatroMessage(null);
    }
    
    dispatch(setLoading(false));
  };

  const handleHorary = (e) => {
    // Now we're using the id directly from the availability data
    
    // Standardize time format - remove leading zeros if present
    const standardizedTime = e.value.replace(/^0/, '');
    
    // Store standardized format in the form
    setValue("horary", standardizedTime);
    
    // Si estamos en una escuela local, guardamos el id_activity de la actividad seleccionada
    if (isLocalSchool && activity?.id) {
      setValue("id_activity", activity.id);
    }
    
    // Keep the original format in the UI selection
    setTurn(e);
  };

  // Check if the form has been submitted or field touched to show errors
  const shouldShowError = field => {
    return errors[field] && (formState?.isSubmitted || formState?.touchedFields?.[field]);
  };

  // Handle change in students count
  const handleStudentsCountChange = (e) => {
    const rawValue = e.target.value;
    let value = parseInt(rawValue) || '';
    
    // Enforce the maximum limit directly in the handler
    if (value > 35) {
      value = 35;
      e.target.value = '35';
    }
    
    setValue("people", value);
  };

  // Modificar la función tileDisabled para incluir las nuevas restricciones
  const tileDisabled = ({ date, view }) => {
    // Disable all dates if no dates available
    if (!dates || !Array.isArray(dates) || dates.length === 0) {
      return true;
    }

    // Only handle the month view of the calendar
    if (view !== 'month') {
      return false;
    }

    // Obtener la fecha actual
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Calcular la fecha mínima (7 días después de hoy)
    const minDate = new Date(today);
    minDate.setDate(today.getDate() + 7);
    
    // Deshabilitar cualquier fecha anterior a la fecha mínima (7 días)
    if (date < minDate) {
      return true;
    }

    // Convertir la fecha del calendario al mismo formato que nuestras fechas almacenadas
    const formattedDate = format(date, "yyyy-MM-dd");
    
    // Verificar si la fecha está en nuestra lista de fechas disponibles Y tiene id_state = 1 (activo)
    const isAvailable = dates.some(availableDate => 
      availableDate.formattedDate === formattedDate && availableDate.id_state === 1
    );

    // Si la fecha no está disponible según las reglas existentes, deshabilitarla
    if (!isAvailable) {
      return true;
    }

    // NUEVAS RESTRICCIONES:
    
    // 1. Obtener el día de la semana (0 = domingo, 1 = lunes, ..., 6 = sábado)
    const dateDayOfWeek = date.getDay();
    
    // Escuelas premium NO locales: solo pueden reservar los miércoles
    if (!isLocalSchool && isPremiumNonLocalSchool) {
      // Si es miércoles (3), verificar si está disponible en el listado devuelto por la API
      if (dateDayOfWeek === 3) {
        // Verificar si la fecha está en nuestro listado filtrado
        const dateString = format(date, "yyyy-MM-dd");
        const isAvailableWednesday = dates.some(d => d.formattedDate === dateString);
        return !isAvailableWednesday; // Deshabilitar si NO está disponible
      }
      // Si no es miércoles, deshabilitar
      return true;
    }
    
    // Para todas las demás escuelas (locales y no locales no premium): 
    // lunes (1), martes (2) y jueves (4)
    if (dateDayOfWeek !== 1 && dateDayOfWeek !== 2 && dateDayOfWeek !== 4) {
      return true;
    }
    
    // La fecha pasa todas las restricciones
    return false;
  };

  const handleDateChange = (value) => {
    if (!value) return;
    
    const selectedDate = format(new Date(value), "yyyy-MM-dd");
    setDate(selectedDate);
    setValue("date", selectedDate);
    
    // Solo para escuelas NO LOCALES PREMIUM, establecemos automáticamente un valor para el horario
    if (shouldDisableTimeSelection) {
      // Usar un valor fijo para indicar "Todo el día" con id_activity = 4
      const allDayOption = {
        id: 4, // ID para indicar TODAS LAS ACTIVIDADES - importante para la reserva
        id_activity: 4, // Agregar explícitamente id_activity para asegurar que se use en la reserva
        value: "0:00",
        label: "0:00",
        horario: "0:00", // Establecer un horario fijo para todo el día
        activityName: "TODAS LAS ACTIVIDADES"
      };
      
      // Establecer el horario automáticamente sin mostrar el selector
      setTurn(allDayOption);
      setValue("horary", allDayOption.value);
      
      // Además, guardar explícitamente el id_activity para uso en la reserva
      setValue("id_activity", 4);
      
      console.log("Escuela NO LOCAL PREMIUM: Horario establecido automáticamente a", allDayOption);
      return;
    }
    
    // Para escuelas locales y no locales no premium, seguimos el flujo normal
    // Reset the time selection
    setTurn("");
    setValue("horary", "");
    
    // If we have the obra de teatro message, we don't need to fetch times
    if (teatroMessage) return;
    
    // Instead of making another API call, filter the dates we already have
    // to find all time slots available for the selected date
    if (dates && Array.isArray(dates)) {
      const availableTimesForDate = dates.filter(d => d.formattedDate === selectedDate);
      
      if (availableTimesForDate.length > 0) {
        // Map the times to the format expected by the Select component
        const timeOptions = availableTimesForDate.map(slot => ({
          id: slot.id, // Using the id from the availability response
          value: slot.time, // Already adjusted in the getDates function
          label: slot.time, // Solo la hora para todas las escuelas
          horario: slot.time, // For compatibility with existing code
          activityName: slot.activityName // Guardar el nombre de la actividad
        }));
        
        // Sort the times
        const sortedTimes = sortArray(timeOptions);
        setHoraries(sortedTimes);
        console.log("Available times for selected date:", sortedTimes);
      } else {
        setHoraries([]);
        console.log("No available times for the selected date");
      }
    }
  };

  return (
    <div className="step-form">
      <div>
        <h6 className="text-center">Turno</h6>
      </div>
      <div className="forms">
        {!isSchoolSelected ? (
          <div className="alert alert-info">
            Por favor seleccione primero una escuela en el paso anterior.
          </div>
        ) : (
          <Form.Group>
            {/* Solo ocultar selector de actividades para escuelas no locales premium */}
            {!shouldUseAllActivities && (
              <Row className="mb-3">
                <Col sm={12} md={6} lg={6}>
                  <Form.Label>Actividades</Form.Label>
                  <Select
                    name="actividades"
                    control={control}
                    options={activities?.length ? activities : [emptyOption]}
                    handleChange={handleSelect}
                    value={activity ? activity : ""}
                    controlled={true}
                    className={`${shouldShowError('actividades') ? "is-invalid" : ""}`}
                  />
                  {shouldShowError('actividades') && (
                    <Form.Control.Feedback type="invalid">
                      {errors?.actividades?.message}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Row>
            )}

            {/* Mostrar calendario basado en actividad */}
            {(((!shouldUseAllActivities && activity) || (shouldUseAllActivities && activity))) && (
              <Row className="my-5">
                <h6 className="form-subtitle">Turno</h6>
                <Col sm={12} md={6} className="horary">
                  <Form.Label>Seleccionar día</Form.Label>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Calendar
                          onChange={(value) => handleDateChange(value)}
                          tileDisabled={tileDisabled}
                          className={`${shouldShowError('date') ? "is-invalid" : ""}`}
                          allowPartialRange
                        />
                      );
                    }}
                  />
                  {shouldShowError('date') && (
                    <Form.Control.Feedback type="invalid">
                      {errors.date?.message}
                    </Form.Control.Feedback>
                  )}
                </Col>

                {teatroMessage ? (
                  <Col sm={12} md={6} className="ps-3 mt-8 text-blue-600">
                    <span>{teatroMessage}</span>
                  </Col>
                ) : (
                  // Solo mostrar selector de horario si no es escuela premium no local
                  date && !shouldDisableTimeSelection && (
                    <Col sm={12} md={6} className="ps-3">
                      <Form.Label>Seleccionar horario</Form.Label>
                      <Select
                        control={control}
                        options={horaries?.length ? horaries : [emptyOption]}
                        handleChange={handleHorary}
                        name="horary"
                        controlled={true}
                        value={turn} // Add this line to set the selected value
                        className={`${shouldShowError('horary') ? "is-invalid" : ""}`}
                      />
                      {shouldShowError('horary') && (
                        <Form.Control.Feedback type="invalid">
                          {errors.horary?.message}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  )
                )}
                
                {/* Para escuelas NO LOCALES PREMIUM, mostrar un mensaje que indica que es todo el día */}
                {date && shouldDisableTimeSelection && !teatroMessage && (
                  <Col sm={12} md={6} className="ps-3">
                    <div className="info-message mt-4">
                      <p><strong>Horario:</strong> Todo el día</p>
                      <p className="text-muted">Las actividades se realizan durante todo el día.</p>
                    </div>
                  </Col>
                )}
              </Row>
            )}

            {/* Nuevo campo para la cantidad de estudiantes */}
            {(date && (((!shouldDisableTimeSelection && turn) || shouldDisableTimeSelection)) && !teatroMessage) && (
              <Row className="mb-3 mt-4">
                <h6 className="form-subtitle">Información adicional</h6>
                <Col sm={12}>
                  <Form.Label>Cantidad de estudiantes</Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    max="35" 
                    placeholder="Ingrese la cantidad de estudiantes"
                    {...register("people", {
                      required: "Este campo es obligatorio",
                      min: {
                        value: 1,
                        message: "Debe haber al menos 1 estudiante"
                      },
                      validate: value => value <= 35 || "Se permite un máximo de 35 estudiantes",
                      valueAsNumber: true
                    })}
                    className={`${shouldShowError('people') ? "is-invalid" : ""}`}
                    onChange={handleStudentsCountChange}
                    style={{ height: "38px" }}
                  />
                  {shouldShowError('people') && (
                    <Form.Control.Feedback type="invalid">
                      {errors.people?.message}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Row>
            )}
          </Form.Group>
        )}
      </div>
    </div>
  );
};

export default TurnForm;