import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

import { axiosNewAPI } from "../../api/axios";
import DateModal from "../dateModal/DateModal";
import { setLoading } from "../../redux/formDataSlice";
import { useDispatch } from "react-redux";

const Callendar = () => {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [turn, setTurn] = useState("");

  const getDates = async () => {
    try {
      dispatch(setLoading(true));
      axiosNewAPI
        .get("reservations")
        .then((response) => {
          console.log(response.data);
          let result = response.data.map((e) => {
            // Extraer la fecha y hora del formato ISO
            const origDate = new Date(e.activitiesavailability.date);
            
            // Corregir el desfase horario añadiendo 3 horas
            const dateObj = new Date(origDate.getTime() + (3 * 60 * 60 * 1000));
            const timeString = dateObj.toTimeString().slice(0, 5); // Obtiene HH:MM
            
            // Crear fecha ISO con la corrección horaria
            const correctedStart = dateObj.toISOString();
            
            return {
              ...e,
              reservationId: e.id,
              title: e.activity.activity,
              date: dateObj.toISOString().split('T')[0], // Formato YYYY-MM-DD
              start: correctedStart, // Usar la fecha completa corregida como start
              people: e.people,
              estado: e.state.name,
              horario: timeString, // Horario corregido
            };
          });

          setEvents(result);
          dispatch(setLoading(false));
          return;
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoading(false));
          return;
        });
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getDates();
  }, []);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const renderEventContent = (content) => {
    const eventData = content.event.extendedProps;
    
    // Verificar si es una actividad del tipo "TODAS" (escuela NO local)
    const isTodas = content.event.title === "TODAS LAS ACTIVIDADES" || 
                    eventData.activity?.activity === "TODAS" ||
                    eventData.activity?.activity === "TODAS LAS ACTIVIDADES";
    
    return (
      <>
        <div
          className={`fc-daygrid-event-dot estado-${eventData.estado?.toLowerCase() || ""}`}
        ></div>
        {/* Mostrar la hora solo si NO es una actividad "TODAS" */}
        {!isTodas && (
          <div
            className={`fc-event-time mx-2 estado-${eventData.estado?.toLowerCase() || ""}`}
          >
            {content.timeText} hs
          </div>
        )}
        <div className="fc-event-title">
          <span className="me-2">-</span>
          <span
            className={`fw-bold estado-${eventData.estado?.toLowerCase() || "text-black"}`}
          >
            {content.event.title} ({eventData.people} personas)
            {isTodas && <span className="ms-1">(Todo el día)</span>}
          </span>
        </div>
      </>
    );
  };

  const handleEventClick = (eventInfo) => {
    handleShow();
    setTurn(eventInfo?.event?.extendedProps);
  };

  return (
    <div style={{ minWidth: 400 }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        buttonText={{
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Dia",
          list: "Lista",
        }}
        locales={[esLocale]}
      />
      {show ? (
        <DateModal
          show={show}
          handleClose={handleClose}
          data={turn}
          setEvents={setEvents}
          getData={getDates}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Callendar;
