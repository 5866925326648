import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import { axiosNewAPI } from "../../api/axios";
import Swal from "sweetalert2";
import { stylesReactSelect } from "../select/styles";

const AddTurnModal = ({ show, handleClose, activities, refreshData }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const resetForm = () => {
    setSelectedDate("");
    setSelectedTime("");
    setSelectedActivity(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDate || !selectedTime || !selectedActivity) {
      return Swal.fire({
        title: "¡Error!",
        text: "Todos los campos son obligatorios",
        icon: "error",
      });
    }

    try {
      setIsLoading(true);

      // Combine date and time into a single ISO date string
      const dateTimeString = `${selectedDate}T${selectedTime}:00.000Z`;

      // Create the request payload
      const payload = {
        id_activity: selectedActivity.value,
        date: dateTimeString,
        id_state: 1, // Active state
        number_of_quotas: null // Default quota
      };

      // Make the POST request to create a new availability
      const response = await axiosNewAPI.post(
        "/activities/available",
        payload
      );

      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          title: "¡Éxito!",
          text: "Turno agregado correctamente",
          icon: "success",
        });
        
        resetForm();
        handleClose();
        // Refresh the data in the parent component
        refreshData();
      } else {
        Swal.fire({
          title: "¡Error!",
          text: "Ha ocurrido un error al agregar el turno",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error adding turn:", error);
      Swal.fire({
        title: "¡Error!",
        text: error.response?.data?.message || "Ha ocurrido un error al agregar el turno",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar nuevo turno</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Hora</Form.Label>
            <Form.Control
              type="time"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Actividad</Form.Label>
            <ReactSelect
              options={activities}
              onChange={(selected) => setSelectedActivity(selected)}
              value={selectedActivity}
              styles={stylesReactSelect}
              isSearchable
              required
              placeholder="Seleccionar actividad"
            />
          </Form.Group>

          <div className="flex justify-content-end">
            <Button 
              variant="outline-secondary" 
              onClick={handleClose} 
              className="me-3"
              disabled={isLoading}
            >
              Cancelar
            </Button>
            <Button 
              variant="outline-success" 
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Guardando..." : "Guardar"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddTurnModal;
