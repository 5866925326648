import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { columns } from "./columns";
import { useState } from "react";
import axios, { axiosTurnosAPI, axiosNewAPI } from "../../api/axios";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormLabel,
  Row,
} from "react-bootstrap";
import { formatLocalDate } from "../../utils/utils";
import EditTurns from "../editTurns/EditTurns";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/auth";
import { avariableHorary, emptyOption } from "../../constants/form";
import ReactSelect from "react-select";
import ChangeActivity from "../changeActivity";
import useUser from "../../hooks/useUser";
import { stylesReactSelect } from "../select/styles";
import useAxiosTurnosAPI from "../../hooks/useAxiosTurnosAPI";
import AddTurnModal from "../addTurnModal/AddTurnModal";

const TurnsTable = () => {
  const navigate = useNavigate();
  const user = useUser();

  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState("");
  const [isNoLocalSchool, setIsNoLocalSchool] = useState(false);

  const [dates, setDates] = useState("");
  const [activities, setActivities] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [horary, setHorary] = useState("");
  const [enabled, setEnabled] = useState("");
  const [activityFilter, setActivityFilter] = useState("");

  const [show, setShow] = useState(false);
  const [activity, setActivity] = useState("");
  
  // Add state for controlling the add turn modal
  const [showAddModal, setShowAddModal] = useState(false);

  const getDates = async () => {
    try {
      let response = await axiosNewAPI.get("activities/schools");

      if (response?.data?.length) {
        // Flatten the nested structure of activities and their availabilities
        const flattenedData = [];
        
        response.data.forEach((activity) => {
          if (activity.availabilities && activity.availabilities.length > 0) {
            // For each availability in this activity, create a record
            activity.availabilities.forEach((availability) => {
              flattenedData.push({
                ...availability,
                activity: activity // Store the entire activity object
              });
            });
          } else {
            // If no availabilities, still add the activity with empty availability data
            flattenedData.push({
              id: `empty-${activity.id}`,
              activity: activity,
              date: null,
              id_state: null
            });
          }
        });

        setData(flattenedData);
        setShowData(flattenedData);

        // Process dates for filtering
        let dates = flattenedData
          .filter(item => item.date) // Only include items with dates
          .map((e) => {
            return {
              ...e,
              label: formatLocalDate(e?.date),
              value: e?.date,
            };
          });

        let result = [];
        dates.forEach((fecha) => {
          if (!result.some((e) => e.date === fecha.date)) {
            result.push(fecha);
          }
        });

        setDates(result.sort((a, b) => new Date(a?.date) - new Date(b?.date)));
      }
    } catch (err) {
      console.error("Error fetching activities/schools:", err);
    }
  };

  // Function to map id_state to readable text
  const getStateText = (stateId) => {
    switch(stateId) {
      case 1: return "Activo";
      case 2: return "Pendiente";
      case 3: return "Confirmado";
      case 4: return "Cancelado";
      case 5: return "Completado";
      case 6: return "Inactivo";
      default: return "Desconocido";
    }
  };

  // Create options for state filter
  const stateOptions = [
    { value: "", label: "Todos" },
    { value: "1", label: "Activo" },
    { value: "2", label: "Pendiente" },
    { value: "3", label: "Confirmado" },
    { value: "4", label: "Cancelado" },
    { value: "5", label: "Completado" },
    { value: "6", label: "Inactivo" }
  ];

  useEffect(() => {
    getDates();
    return () => {
      setData([]);
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosNewAPI.get("activities");
        let result = response.data?.map((e) => {
          return {
            ...e,
            label: e.activity,
            value: e.id,
          };
        });

        setActivities(result);
      } catch (err) {
        console.error("Error fetching activities:", err);
      }
    };
    getData();

    return () => {
      setActivities([]);
    };
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    
    // First apply the timezone adjustment (+3 hours)
    const adjustedDate = new Date(date);
    adjustedDate.setHours(date.getHours() + 3);
    
    // Now use the adjusted date for formatting
    return `${adjustedDate.getDate().toString().padStart(2, '0')}/${(adjustedDate.getMonth() + 1).toString().padStart(2, '0')}/${adjustedDate.getFullYear()}`;
  };

  const formatTime = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    
    // Add 3 hours to adjust for timezone difference
    date.setHours(date.getHours() + 3);
    
    // Return time from adjusted date
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    let result = data?.filter((e) => {
      // Skip filtering for entries without dates
      if (!e.date) return false;
      
      const time = e.date ? formatTime(e.date) : '';
      
      let fecha =
        (startDate ? new Date(e.date) >= new Date(startDate) : true) &&
        (endDate ? new Date(e.date) <= new Date(endDate) : true);
      let horario = time?.includes(horary);
      // Update state filter to use the correct id_state value
      let estadoFiltro = enabled ? e?.id_state?.toString() === enabled : true;
      // Update activity filter to match ID
      let actividad = activityFilter ? e?.activity?.id?.toString() === activityFilter.toString() : true;

      return fecha && horario && estadoFiltro && actividad;
    });

    setShowData(result);
  }, [data, startDate, endDate, horary, enabled, activityFilter]);

  const handleShow = (data) => {
    // Check if it's a Wednesday and activity is "TODAS"
    const date = data.date ? new Date(data.date) : null;
    const isWednesday = date ? date.getDay() === 2 : false; // 0 = Sunday, 1 = Monday, ..., 3 = Wednesday
    const isTodasActivity = data.activity.activity === "TODAS";
    
    // Set the flag for non-local school (Wednesday + TODAS activity)
    setIsNoLocalSchool(isWednesday && isTodasActivity);
    
    // Set the data for editing
    setEditData(data);
    return setShowEdit(true);
  };
  const handleClose = () => setShowEdit(false);

  const changeState = async (data, state) => {
    if (data) {
      // Check if this is a "TODAS" activity (for non-local schools)
      const isTodasActivity = data.activity.activity === "TODAS";
      
      // Create different confirmation messages based on activity type
      let confirmTitle = `<h3>${state} turno</h3>`;
      let confirmMessage = `<p>¿Estás seguro de ${state.toLowerCase()} este turno${isTodasActivity ? ' para escuelas no locales' : ''}?</p>`;
      
      // For "TODAS" activities, only show the date without time
      let timeInfo = '';
      if (isTodasActivity) {
        timeInfo = `<p><span style="font-weight:600;">Turno:</span> ${
          data.date ? formatDate(data.date) : "Sin fecha"
        }</p>`;
      } else {
        timeInfo = `<p><span style="font-weight:600;">Turno:</span> ${
          data.date ? formatDate(data.date) : "Sin fecha"
        } a las ${
          data.date ? formatTime(data.date) : "Sin horario"
        }</p>`;
      }

      return Swal.fire({
        title: confirmTitle,
        html: `<div>
          ${confirmMessage}
          ${timeInfo}
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            let apiResponse;
            
            // Use different endpoints based on the action (disable vs enable)
            if (state === "Deshabilitar") {
              // Use the disable endpoint for disabling
              apiResponse = await axiosNewAPI.put(`activities/availability/${data.id}/disable`, {
                id_user: user?.id,
              });
            } else {
              // For enabling, use the new enable endpoint
              apiResponse = await axiosNewAPI.put(`activities/availability/${data.id}/enable`);
            }

            if (apiResponse.status === 200) {
              await getDates();

              return Swal.fire({
                title: "¡Éxito!",
                text: "El turno se actualizó exitosamente.",
                icon: "success",
              });
            } else {
              return Swal.fire({
                title: "¡Error!",
                text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
                icon: "error",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.status === 403 || err.response?.status === 401) {
            return Swal.fire({
              title: "Error!",
              text: "Su sesión expiro, vuelva a iniciar sesión",
              icon: "warning",
              willClose: () => {
                logout();
                return navigate("/");
              },
            });
          }
          return Swal.fire({
            title: "Error!",
            text: "Ocurrio un error durante el proceso, intentelo nuevamente",
            icon: "error",
          });
        });
    } else {
      return;
    }
  };

  const handleActivity = (e) => {
    setShow(true);
    setActivity(e);
  };

  const formatData = (array) => {
    try {
      return array.map((e) => {
        // Format time for display using 24-hour format
        const time = e.date ? formatTime(e.date) : '';
        
        // Check if time is "00:00" (all day)
        const isAllDay = time === "00:00";
        
        // Check if activity is "TODAS" to add tooltip
        const activityName = e.activity.activity || "Sin actividad";
        const activityDisplay = activityName === "TODAS" ? (
          <span>
            TODAS{" "}
            <Tooltip title="Turno para una escuela NO LOCAL">
              <i className="fa fa-info-circle" style={{ cursor: 'help', color: '#1890ff' }}></i>
            </Tooltip>
          </span>
        ) : activityName;
        
        // Check if the date is a Wednesday and activity is "TODAS"
        const date = e.date ? new Date(e.date) : null;
        const isWednesday = date ? date.getDay() === 2 : false; // 0 = Sunday, 1 = Monday, ..., 3 = Wednesday
        const isTodasOnWednesday = isWednesday && activityName === "TODAS";
        
        return {
          ...e,
          fecha: e.date ? formatDate(e.date) : "Sin fecha",
          // Don't show time if it's 00:00 (all day)
          horario: isAllDay ? "" : (time || "Sin horario"),
          estado: getStateText(e.id_state) || "Sin estado",
          actividad: activityDisplay,
          action: (function a() {
            return (
              <div className="d-flex justify-content-center">
                <Button
                  variant="outline-primary"
                  className="me-3"
                  onClick={() => handleShow(e)}
                >
                  <i className="fa fa-edit me-2"></i> Editar
                </Button>
                {/* Modified condition to include id_state 2 (Pendiente) */}
                {(e.id_state === 1 || e.id_state === 2) ? (
                  <Button
                    className="me-3"
                    variant="outline-danger"
                    onClick={() => changeState(e, "Deshabilitar")}
                  >
                    <i className="fa fa-times me-2"></i> Deshabilitar
                  </Button>
                ) : (
                  // Only show Habilitar button for Inactivo (id_state=6) or Cancelado (id_state=4)
                  (e.id_state === 6 || e.id_state === 4) && (
                    <Button
                      className="me-3"
                      variant="outline-success"
                      onClick={() => changeState(e, "Habilitar")}
                    >
                      <i className="fa fa-check me-2"></i> Habilitar
                    </Button>
                  )
                )}
                {/* Hide Actividad button if it's Wednesday and activity is "TODAS" */}
                {!isTodasOnWednesday && (
                  <Button
                    className="me-3"
                    variant="outline-info"
                    onClick={() => handleActivity(e)}
                  >
                    <i className="fa fa-bus me-2"></i> Actividad
                  </Button>
                )}
              </div>
            );
          })(),
        };
      });
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const handleDates = (e) => {
    if (e.target.name === "fecha_desde") {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };

  const handleHoraries = (e) => {
    if (e) {
      setHorary(e.value);
    } else {
      setHorary("");
    }
  };

  const handleChange = (e) => {
    setEnabled(e?.value || "");
  };

  const handleActivityFilter = (selected) => {
    setActivityFilter(selected ? selected.value : "");
  };

  // Function to handle adding a new time slot
  const handleAddTimeSlot = () => {
    setShowAddModal(true);
  };

  // Add a function to clear all filters
  const handleClearFilters = () => {
    setStartDate("");
    setEndDate("");
    setActivityFilter("");
    setHorary("");
    setEnabled("");
  };

  return (
    <div>
      <div>
        <Row className="mt-4 mb-3">
          <Col>
            <Form.Label style={{ fontSize: 18, fontWeight: 600 }}>
              Fecha desde:
            </Form.Label>
            <Form.Control
              name="fecha_desde"
              type="date"
              onChange={handleDates}
              value={startDate}
              className="form-control"
            />
          </Col>
          <Col>
            <Form.Label style={{ fontSize: 18, fontWeight: 600 }}>
              Fecha hasta:
            </Form.Label>
            <Form.Control
              name="fecha_hasta"
              type="date"
              onChange={handleDates}
              value={endDate}
              className="form-control"
            />
          </Col>
          <Col>
            <FormLabel style={{ fontSize: 18, fontWeight: 600 }}>
              Actividad
            </FormLabel>
            <ReactSelect
              options={
                activities?.length
                  ? [emptyOption, ...activities]
                  : [emptyOption]
              }
              name="actividad"
              onChange={handleActivityFilter}
              value={
                activityFilter && activities?.length
                  ? activities.find(a => a.value === parseInt(activityFilter)) || ""
                  : ""
              }
              styles={stylesReactSelect}
              isClearable
              placeholder="Seleccionar actividad"
            />
          </Col>
          <Col>
            <FormLabel style={{ fontSize: 18, fontWeight: 600 }}>
              Horarios
            </FormLabel>
            <ReactSelect
              options={avariableHorary}
              name="horarios"
              onChange={handleHoraries}
              value={horary ? { label: horary, value: horary } : ""}
              styles={stylesReactSelect}
              isClearable
              placeholder="Seleccionar horario"
            />
          </Col>
          <Col>
            <FormLabel style={{ fontSize: 18, fontWeight: 600 }}>
              Estado de la actividad
            </FormLabel>
            <ReactSelect
              options={stateOptions}
              name="estado"
              onChange={handleChange}
              value={enabled ? stateOptions.find(opt => opt.value === enabled) : stateOptions[0]}
              styles={stylesReactSelect}
              isClearable
              placeholder="Seleccionar estado"
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-center gap-3 mb-3">
          <Button variant="outline-primary" onClick={getDates}>
            Actualizar
          </Button>
          <Button variant="outline-secondary" onClick={handleClearFilters}>
            <i className="fa fa-eraser me-2"></i> Limpiar filtros
          </Button>
          <Button variant="outline-success" onClick={handleAddTimeSlot}>
            <i className="fa fa-plus-circle me-2"></i> Agregar turno
          </Button>
        </div>
      </div>
      <Table
        dataSource={formatData(showData)}
        columns={columns.turns}
        rowKey={(row) => row?.id}
      />

      {showEdit ? (
        <EditTurns
          show={showEdit}
          handleClose={handleClose}
          data={editData}
          getDates={getDates}
          isNoLocalSchool={isNoLocalSchool}  // Pass the flag to EditTurns
        />
      ) : (
        ""
      )}
      {show ? (
        <ChangeActivity
          show={show}
          handleClose={() => setShow(false)}
          data={activity}
          setData={setData}
          getData={getDates}
          table="turns"
        />
      ) : (
        ""
      )}
      
      {/* Add the new modal component */}
      <AddTurnModal
        show={showAddModal}
        handleClose={() => setShowAddModal(false)}
        activities={activities}
        refreshData={getDates}
      />
    </div>
  );
};

export default TurnsTable;
