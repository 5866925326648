import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { axiosNewAPI } from "../../api/axios";
import Swal from "sweetalert2";

const EditTurns = ({ show, handleClose, data, getDates, isNoLocalSchool }) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // When the modal opens with data, separate the date and time
  useEffect(() => {
    if (data && data.date) {
      // Create a date object from the ISO string
      const dateObj = new Date(data.date);
      
      // Add 3 hours to adjust for timezone
      dateObj.setHours(dateObj.getHours() + 3);
      
      // Format date as YYYY-MM-DD for the date input
      const formattedDate = dateObj.toISOString().split('T')[0];
      setDate(formattedDate);
      
      // Format time as HH:MM for the time input
      const hours = dateObj.getHours().toString().padStart(2, '0');
      const minutes = dateObj.getMinutes().toString().padStart(2, '0');
      setTime(`${hours}:${minutes}`);
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!date) {
      return Swal.fire({
        title: "Error",
        text: "La fecha es obligatoria",
        icon: "error"
      });
    }

    try {
      setIsLoading(true);
      
      // For non-local schools, always use 00:00 as the time
      const timeToUse = isNoLocalSchool ? "00:00" : (time || "00:00");
      
      // Combine the date and time into a single ISO string
      const isoDateString = `${date}T${timeToUse}:00.000Z`;
      
      // Use the correct endpoint with the activity ID (not availability ID)
      // and include id_activity in the request body
      const response = await axiosNewAPI.put(`activities/availability/${data.id}`, {
        date: isoDateString
      });
      
      if (response.status === 200) {
        Swal.fire({
          title: "¡Éxito!",
          text: "Turno actualizado correctamente",
          icon: "success"
        });
        
        handleClose();
        // Refresh the parent component data
        getDates();
      } else {
        throw new Error("Error updating turn");
      }
    } catch (error) {
      console.error("Error updating turn:", error);
      Swal.fire({
        title: "Error",
        text: error.response?.data?.error || "Ocurrió un error al actualizar el turno",
        icon: "error"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Turno</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Hora</Form.Label>
            <Form.Control
              type="time"
              value={isNoLocalSchool ? "00:00" : time}
              onChange={(e) => setTime(e.target.value)}
              disabled={isNoLocalSchool}
            />
            <Form.Text className="text-muted">
              {isNoLocalSchool 
                ? "Turnos para escuelas NO LOCALES siempre tienen horario 00:00" 
                : "Dejar vacío o usar 00:00 para turnos de todo el día"}
            </Form.Text>
          </Form.Group>
          
          <div className="flex justify-content-center mt-5">
            <Button 
              variant="outline-danger"
              className="me-3"
              onClick={handleClose} 
              disabled={isLoading}
            >
              Cancelar
            </Button>
            <Button 
              variant="outline-primary" 
              type="submit"
              className="me-3"
              disabled={isLoading}
            >
              {isLoading ? "Guardando..." : "Guardar cambios"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditTurns;
